.app-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 10;
}

.app-bar .content {
  padding: 0 0.4rem;
  width: 100%;
  height: 1.413333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.32rem;
}

.app-bar .content .logo {
  flex: 0 0 2.56rem;
  width: 2.56rem;
  height: 0.56rem;
  background: url("~@/assets/img/logo.png") no-repeat center/100% 100%;
}

.app-bar .content .ismenu {
  width: 0.493333rem;
  height: 0.493333rem;
  background: url("~@/assets/img/three.png") no-repeat center/100% 100%;
}

.app-bar .content .openMenu {
  background: url("~@/assets/img/icon_close.png") no-repeat center/100% 100%;
}

.app-bar .content .bar-list {
  display: flex;
  align-items: center;
}

.app-bar .content .bar-list .bar-item {
  font-size: 0.186667rem;
  color: #123049;
  line-height: 0.266667rem;
  cursor: pointer;
}

.app-bar .content .bar-list .bar-item:not(:last-child) {
  padding-right: 1.093333rem;
}

.app-bar .content .bar-list .bar-item.select {
  font-weight: 600;
  color: #009B93;
  position: relative;
}

.app-bar .content .bar-list .bar-item.select::before {
  content: '';
  position: absolute;
  top: -0.133333rem;
  left: -0.32rem;
  background: url("~@/assets/img/icon_circle.png") no-repeat center/100% 100%;
  width: 0.32rem;
  height: 0.3388rem;
  display: inline-block;
  vertical-align: text-top;
}

.cover {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(0,0,0,0.2);
  z-index: 9;
}