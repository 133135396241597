.comp-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 1.066667rem 0;
}

.comp-wrapper .title {
  font-size: 0.533333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #123049;
}

.comp-wrapper .hit {
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #123049;
  padding: 0.64rem 0 0.4rem;
}

.comp-wrapper .hit1 {
  width: 6.4rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8F98A3;
  line-height: 0.48rem;
  padding-bottom: 0.773333rem;
  text-align: center;
  padding-bottom: 0.8rem;
}

.comp-wrapper .btn {
  width: 2rem;
  height: 0.64rem;
  background: #21948C;
  border-radius: 0.106667rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}