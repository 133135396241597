.slick-dots-swiper {
  left: 0 !important;
  right: 24 !important;
  bottom: -0.653333rem !important;
}

.slick-dots-swiper li {
  margin-right: 0.32rem !important;
  width: 0.24rem !important;
  height: 0.24rem !important;
}

.slick-dots-swiper li button {
  width: 0.24rem !important;
  height: 0.24rem !important;
  background: #fff !important;
  border-radius: 50% !important;
}

.slick-dots-swiper li.slick-active button {
  background: #fff !important;
}

.slick-dots-swiper-index {
  left: 0 !important;
  bottom: 0.08rem !important;
  right: 0.16rem !important;
}

.slick-dots-swiper-index li {
  margin-right: 0.16rem !important;
  width: 0.16rem !important;
  height: 0.16rem !important;
}

.slick-dots-swiper-index li button {
  width: 0.16rem !important;
  height: 0.16rem !important;
  background: #fff !important;
  border-radius: 50% !important;
}

.slick-dots-swiper-index li.slick-active button {
  background: #fff !important;
}