.bar-list {
  border-top: 0.013333rem solid #BCC7CD;
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
  max-height: calc(100vh - 1.146667rem);
  overflow: auto;
}

.bar-list .bar-item {
  width: 100%;
  height: 1.733333rem;
  line-height: 1.733333rem;
  padding-left: 0.666667rem;
  cursor: pointer;
  border-bottom: 0.013333rem solid #E2E6E9;
}

.bar-list .bar-item .firstItem {
  color: #123049;
  font-size: 0.4rem;
}

.bar-list .bar-item .select {
  font-weight: 600;
  color: #009B93;
  background: url("~@/assets/img/icon_focus.png") no-repeat right/0.48rem 0.48rem;
}

.bar-list .select_con {
  display: flex;
  flex-direction: column;
  border-bottom: 0.013333rem solid #E2E6E9;
}

.bar-list .select_con .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.bar-list .select_con .title .title_con {
  padding-left: 0.666667rem;
  color: #123049;
  font-size: 0.4rem;
  height: 1.733333rem;
  line-height: 1.733333rem;
}

.bar-list .select_con .title .title_icon {
  width: 0.48rem;
  height: 0.48rem;
  background: url("~@/assets/img/icon_show.png") no-repeat right/100% 100%;
}

.bar-list .select_con .title .title_icon2 {
  transform: rotate(180deg);
}

.bar-list .select_con .secItem {
  color: #123049;
  font-size: 0.4rem;
  height: 1.173333rem;
  line-height: 1.173333rem;
  padding-left: 1.066667rem;
}

.bar-list .select_con .select {
  font-weight: 600;
  color: #009B93;
  background: url("~@/assets/img/icon_focus.png") no-repeat right/0.48rem 0.48rem;
}