.page-wrapper {
  display: flex;
  flex-direction: column;
}

.page-wrapper .swiper-wrapper {
  width: 100%;
  height: 5.093333rem;
}

.page-wrapper .swiper-wrapper img {
  width: 100%;
  height: 5.12rem;
}

.page-wrapper .info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 1.08rem;
  text-align: left;
}

.page-wrapper .info .info-head {
  width: 100%;
  height: 2.4rem;
  background: #123049;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.533333rem 0;
}

.page-wrapper .info .info-head .head-title-zh {
  font-size: 0.64rem;
  font-weight: 600;
  color: #FFFFFF;
}

.page-wrapper .info .info-head .head-title-en {
  font-size: 0.266667rem;
  font-weight: 500;
  color: #FFFFFF;
}

.page-wrapper .info .info-title {
  display: flex;
  padding: 1.066667rem 0 0.4rem;
  font-size: 0.533333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #123049;
  line-height: 0.746667rem;
}

.page-wrapper .info .info-hit {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #123049;
  line-height: 0.64rem;
  padding: 0 0.4rem;
}

.page-wrapper .info .info-hit .info-hit-item {
  padding-bottom: 0.266667rem;
  font-size: 0.373333rem;
  opacity: 0.4;
}

.page-wrapper .info .info-hit2 {
  height: 3.92rem;
  overflow: hidden;
}

.page-wrapper .info .show {
  height: 1.2rem;
}

.page-wrapper .info .show .show-all {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.page-wrapper .info .show .show-all .text {
  font-size: 0.4rem;
  color: #009B93;
  line-height: 0.56rem;
}

.page-wrapper .info .show .show-all .colseshow {
  width: 0.48rem;
  height: 0.48rem;
  background: url("~@/assets/img/icon_zhankai.png") no-repeat center/100% 100%;
}

.page-wrapper .info .show .show-all .openshow {
  transform: rotate(180deg);
}