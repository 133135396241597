.footer {
  padding: 0.533333rem 0;
  background: #123049;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1.733333rem;
}

.footer .footer-txt {
  font-size: 0.186667rem;
  color: #878787;
  line-height: 0.226667rem;
  width: 100vw;
  font-size: 0.186667rem;
  font-family: Helvetica;
}

.footer .footer-txt .footer-box {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-bottom: 0.2rem;
}

.footer .footer-txt .footer-box div {
  padding-right: 0.426667rem;
}

.footer .footer-txt .footer-box img {
  flex: 0 0 0.266667rem;
  height: 0.266667rem;
}