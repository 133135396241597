.list-wrapper {
  width: 100%;
  background-color: #F8F9FB;
  padding: 0.96rem 0;
}

.list-wrapper .content {
  width: 100%;
  text-align: center;
  padding: 0 0.4rem;
}

.list-wrapper .content .title {
  font-size: 0.533333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #123049;
  line-height: 0.746667rem;
  text-align: center;
}

.list-wrapper .content .hit {
  font-size: 0.333333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #123049;
  line-height: 0.44rem;
  padding-top: 0.4rem;
  padding: 0 0.106667rem;
}

.list-wrapper .content .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0.266667rem;
}

.list-wrapper .content .list .item {
  width: 3.866667rem;
  flex: 0 0 31%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.786667rem;
}

.list-wrapper .content .list .item .icon {
  width: 1.56rem;
  height: 1.146667rem;
}

.list-wrapper .content .list .item:nth-child(1) .icon {
  background: url("~@/assets/img/page1_icon1.png") no-repeat center/100% 100%;
}

.list-wrapper .content .list .item:nth-child(2) .icon {
  background: url("~@/assets/img/page1_icon2.png") no-repeat center/100% 100%;
}

.list-wrapper .content .list .item:nth-child(3) .icon {
  background: url("~@/assets/img/page1_icon3.png") no-repeat center/100% 100%;
}

.list-wrapper .content .list .item:nth-child(4) .icon {
  background: url("~@/assets/img/page1_icon4.png") no-repeat center/100% 100%;
}

.list-wrapper .content .list .item:nth-child(5) .icon {
  background: url("~@/assets/img/page1_icon5.png") no-repeat center/100% 100%;
}

.list-wrapper .content .list .item:nth-child(6) .icon {
  background: url("~@/assets/img/page1_icon6.png") no-repeat center/100% 100%;
}

.list-wrapper .content .list .item .item-title {
  font-size: 0.48rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #123049;
  line-height: 0.666667rem;
  padding: 0.32rem 0 0.293333rem;
  text-align: center;
}

.list-wrapper .content .list .item .item-hit {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #123049;
  line-height: 0.48rem;
  width: 3.88rem;
  height: 1.466667rem;
  text-align: center;
}