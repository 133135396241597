.comp-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #123049;
  padding: 0.96rem 0.533333rem;
}

.comp-wrapper .title {
  font-size: 0.533333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.746667rem;
  padding-bottom: 0.4rem;
}

.comp-wrapper img {
  width: 100%;
  height: auto;
}

.comp-wrapper .swiper-wrapper {
  width: 7.48rem;
  height: 5.28rem;
}

.comp-wrapper .hit_ {
  width: 100%;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: justify;
  color: #F5F5F5;
  line-height: 0.48rem;
  padding-top: 1.293333rem;
}